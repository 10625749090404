import React, { Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { useQuery } from 'urql';

import getPageMeta from './getPageMeta.gql';
import { Query } from '@AuroraTypes';
import { useAppContext } from '@Contexts/contexts';
import { ClientError } from '@Core/errors/client/ClientError';
import { ContentNotFoundError } from '@Core/errors/client/ContentNotFoundError';
import { isQueryResolved } from '@Core/isQueryResolved';

/**
 * This looks like a react-helmet-async bug where it adds
 * props with `null`s as values instead of skipping them
 * like React does.
 */
const filterUnwantedValues = (obj: any) =>
  Object.entries(obj).reduce<Record<string, any>>((acc, [key, value]) => {
    if (key !== '__typename' && value) {
      acc[key] = value;
    }

    return acc;
  }, {});

interface PageMetaProps {
  pageType: string;
  contentId?: string;
}

export const PageMeta: React.FC<PageMetaProps> = ({ pageType, contentId, children }) => {
  const [{ data, error, fetching }] = useQuery<Query>({
    query: getPageMeta,
    variables: {
      pageType,
      contentId,
    },
  });
  const { site } = useAppContext();

  if (error) {
    throw new ClientError(
      error?.graphQLErrors[0]?.message ?? error?.message ?? 'Cannot fetch PageMeta',
    );
  } else if (!isQueryResolved<Query>(fetching, data)) {
    return null;
  } else if (!data.Content.page.meta) {
    throw new ContentNotFoundError(
      `${contentId} (pageType: ${pageType}, page meta, siteCode: ${site.siteCode})`,
    );
  }

  const { title, links = [], metas = [], schema, url } = data?.Content.page.meta;

  if (url && url !== contentId) {
    return <Redirect to={url} />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(
    () => (
      <Fragment>
        <Helmet>
          <title>{title}</title>
          {links.map((link, i) => (
            <link
              key={i}
              {...filterUnwantedValues(link)}
            />
          ))}
          {metas.map((meta, i) => (
            <meta
              key={i}
              {...filterUnwantedValues(meta)}
            />
          ))}

          {children}
        </Helmet>

        {schema &&
          [].concat(schema).map((snippet, i) => (
            <script
              key={i}
              type="application/ld+json"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(snippet),
              }}
            />
          ))}
      </Fragment>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [title, links, metas, schema],
  );
};
