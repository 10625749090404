import React from 'react';
import { useQuery } from 'urql';

import getNotice from './getNotice.gql';
import { PageNoticeComponent } from './PageNoticeComponent';
import { PageType, Query } from '@AuroraTypes';
import { isQueryResolved } from '@Core/isQueryResolved';

interface PageNoticeProps {
  pageType: PageType;
}

export const PageNotice: React.FC<PageNoticeProps> = ({ pageType }) => {
  const [{ data, error, fetching }] = useQuery<Query>({
    query: getNotice,
    variables: {
      pageType,
    },
  });

  if (error) {
    return null;
  }
  if (!isQueryResolved<Query>(fetching, data)) {
    return null;
  }
  if (!data.Content.notice) {
    return null;
  }

  const notices = data.Content.notice;

  if (!notices.length) {
    return null;
  }

  return <PageNoticeComponent notices={notices} />;
};
