import { DontHydrate } from '@loveholidays/design-system';
import React, { Fragment } from 'react';
import { useQuery } from 'urql';

import getBreadcrumbs from './getBreadcrumbs.gql';
import { PageType } from '@AuroraTypes';
import { Breadcrumb, Query } from '@AuroraTypes';
import { PageMeta } from '@Components/PageMeta/PageMeta';
import { PageNotice } from '@Components/PageNotice/PageNotice';
import { SubHeaderLinks } from '@Components/SubHeaderLinks/SubHeaderLinks';
import { isQueryResolved } from '@Core/isQueryResolved';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';

interface PageProps {
  pageType?: PageType;
  contentId?: string;
  showBreadcrumbsOnMobile?: boolean;
  showSubHeaderLinks?: boolean;
}

export const Page: React.FC<PageProps> = ({
  pageType,
  contentId,
  children,
  showBreadcrumbsOnMobile,
  showSubHeaderLinks = true,
}) => {
  const dynamicPageMeta = useFeatureFlag('DynamicPageMeta');
  const [{ data, fetching }] = useQuery<Query>({
    query: getBreadcrumbs,
    variables: {
      pageType,
      contentId,
    },
  });

  let breadcrumbs: Breadcrumb[] = [];

  if (isQueryResolved<Query>(fetching, data)) {
    breadcrumbs = data.Content.page.breadcrumbs;
  }

  return (
    <Fragment>
      {!!pageType && dynamicPageMeta && (
        <PageMeta
          pageType={pageType}
          contentId={contentId}
        />
      )}

      {!!pageType && (
        <DontHydrate>
          <PageNotice pageType={pageType} />

          {showSubHeaderLinks && (
            <SubHeaderLinks
              breadcrumbs={breadcrumbs}
              showBreadcrumbsOnMobile={showBreadcrumbsOnMobile}
            />
          )}
        </DontHydrate>
      )}

      {children}
    </Fragment>
  );
};
